import React, { useState } from "react";
import { Link } from "react-scroll";
import { BiRestaurant } from "react-icons/bi";
import Button from "../layouts/Button";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import PaymentFormModal from "./PaymentModal";

const Navbar = () => {
    const [menu, setMenu] = useState(false);

    const handleChange = () => {
        setMenu(!menu);
    };

    const closeMenu = () => {
        setMenu(false);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleJoinClick = () => {
        setIsModalOpen(true);
    };
    return (
        <div className=" fixed w-full">
            <div>
                <div className=" flex flex-row justify-between p-5 md:px-32 px-5 bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                    <div className=" flex flex-row items-center cursor-pointer">
                        <span>
                            <BiRestaurant size={32} />
                        </span>
                        <h1 className=" text-xl lg:text-3xl font-semibold">Offpeak</h1>
                    </div>

                    <nav className="hidden md:flex flex-row items-center text-lg font-medium gap-8">
                        <Link
                            to="home"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="hover:text-brightColor transition-all cursor-pointer"
                        >
                            Home
                        </Link>

                        <div className="relative group">
                            <div className=" flex items-center gap-1">
                                <Link
                                    to="dishes"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    className="hover:text-brightColor transition-all cursor-pointer"
                                >
                                    Restaurants & Bars
                                </Link>

                            </div>

                            {/* <ul className="absolute hidden space-y-2 group-hover:block bg-white border border-gray-300 rounded-lg p-5">
                                <li>
                                    <Link
                                        to="dishes"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-gray-800 hover:text-brightColor transition-all cursor-pointer"
                                    >
                                        Spicy
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="dishes"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-gray-800 hover:text-brightColor transition-all cursor-pointer"
                                    >
                                        Tasty
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="dishes"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-gray-800 hover:text-brightColor transition-all cursor-pointer"
                                    >
                                        Delicious
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="dishes"
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className="text-gray-800 hover:text-brightColor transition-all cursor-pointer"
                                    >
                                        Crispy
                                    </Link>
                                </li>
                            </ul> */}
                        </div>

                        <Link
                            to="about"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="hover:text-brightColor transition-all cursor-pointer"
                        >
                            About
                        </Link>

                        {/* <Link
                            to="menu"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="hover:text-brightColor transition-all cursor-pointer"
                        >
                            Menu
                        </Link> */}

                        {/* <Link
                            to="review"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="hover:text-brightColor transition-all cursor-pointer"
                        >
                            Reviews
                        </Link> */}

                        <Link to="about">
                            <Button
                                title="Get Membership"
                                onClick={handleJoinClick}
                            />
                        </Link>
                    </nav>

                    <div className="md:hidden flex items-center">
                        {menu ? (
                            <AiOutlineClose size={25} onClick={handleChange} />
                        ) : (
                            <AiOutlineMenuUnfold size={25} onClick={handleChange} />
                        )}
                    </div>
                </div>

                <div
                    className={` ${menu ? "translate-x-0" : "-translate-x-full"
                        } lg:hidden flex flex-col absolute bg-gray-700 text-white left-0 top-20 font-semibold text-2xl text-center pt-8 pb-4 gap-8 w-full h-fit transition-transform duration-300`}
                >
                    <Link
                        to="home"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="hover:text-brightColor font-thin transition-all cursor-pointer"
                        onClick={closeMenu}
                    >
                        Home
                    </Link>
                    <Link
                        to="dishes"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="hover:text-brightColor  font-thin transition-all cursor-pointer"
                        onClick={closeMenu}
                    >
                        Restaurants & Bars
                    </Link>
                    <Link
                        to="about"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="hover:text-brightColor transition-all font-thin cursor-pointer"
                        onClick={closeMenu}
                    >
                        About
                    </Link>
                    {/* <Link
                        to="menu"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="hover:text-brightColor transition-all cursor-pointer"
                        onClick={closeMenu}
                    >
                        Menu
                    </Link> */}
                    {/* <Link
                        to="review"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className=" hover:text-brightColor transition-all cursor-pointer"
                        onClick={closeMenu}
                    >
                        Reviews
                    </Link> */}

                    {/* <Button className="font-thin" title="Get Membership" onClick={handleJoinClick} /> */}
                </div>
                {isModalOpen && <PaymentFormModal closeModal={() => setIsModalOpen(false)} />}

            </div>
        </div>
    );
};

export default Navbar;
